'use client'
import Link from "next/link"
import Image from "next/image"
import { useState,useEffect } from "react"
import clsx from "clsx"
const Header = () => {
    const [isOpen,setOpen] = useState<boolean>(false)
    const handleMenuOpen = () => setOpen(!isOpen)
    const handleMenuClose = () => setOpen(false)

    return(
        <header className='xl:p-4 shadow fixed top-0 w-screen z-20 bg-white'>
            <div className='relative p-4 xl:p-0 grid max-w-7xl grid-cols-[1fr_auto] xl:grid-cols-12 xl:gap-x-8 mx-auto'>
                <h1 className='flex gap-2 items-center xl:col-span-4 text-xl xl:text-4xl font-bold serif'>
                    <Image src={"/logo.png"} alt={"サン鉄工のロゴマーク"} width={100} height={37}/>
                    <span className='text-sm xl:text-xl'>有限会社</span> サン鉄工
                </h1>
                <div className={clsx('absolute w-screen top-[69px] left-0 overflow-hidden xl:p-0 xl:w-auto xl:h-auto xl:grid xl:static xl:col-span-8 xl:grid-cols-8 xl:gap-x-8 duration-300 z-10 bg-white'
                                     ,isOpen ? 'h-[368px] pb-6 shadow xl:shadow-none':
                                     'h-0 col-span-8 grid-cols-8 gap-x-8')}>
                    <nav className={clsx('xl:col-span-5'
                                         ,isOpen ? "grid col-span-1 items-center font-bold" :
                                         "grid col-span-5 items-center font-bold")}>
                        <ul className='xl:flex justify-end gap-8 text-center'>
                            <li><Link href='/' className='block p-4 xl:p-0' onClick={handleMenuClose}>ホーム</Link></li>
                            <li><Link href='/about' className='block p-4 xl:p-0' onClick={handleMenuClose}>会社概要</Link></li>
                            <li><Link href='/facility' className='block p-4 xl:p-0' onClick={handleMenuClose}>設備一覧</Link></li>
                            <li><Link href='/product' className='block p-4 xl:p-0' onClick={handleMenuClose}>製品一覧</Link></li>
                            <li><Link href='/contact' className='block p-4 xl:p-0' onClick={handleMenuClose}>お問い合わせ</Link></li>
                        </ul>
                    </nav>

                    <Link href='tel:072-257-3565' className='grid py-2 px-6 max-w-xs mx-auto gap-x-2 bg-primary grid-cols-[auto_1fr] items-center justify-center text-white col-span-3 rounded-[20px] text-center'>
                        <Image src='/tel-w.svg' alt='電話のアイコン' width={24} height={24} className='row-span-2'/>
                        <p className='font-bold text-2xl tracking-wider'>072-257-3565</p>
                        <p className='text-xs tracking-wider'>お気軽にお問い合わせください</p>
                    </Link>
                </div>
                <button className='grid items-center z-20 xl:hidden' onClick={handleMenuOpen}>
                    <div className={clsx('relative w-6 h-1 duration-300 rounded-full before:content-[""] before:absolute before:w-6 before:h-1 before:rounded-full before:bg-primary before:left-0 before:duration-300 after:content-[""] after:absolute after:w-6 after:h-1 after:rounded-full after:bg-primary after:left-0 after:duration-300',
                                         isOpen ? 'bg-transparent before:rotate-45 after:-rotate-45':
                                         'bg-primary before:-translate-y-2 after:translate-y-2')}>
                    </div>
                </button>
            </div>
        </header>
    )
}

export default Header